<template>
  <div class="qrmanage">
    <div class="panel-title">
      {{ $t("QrGroup") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-row :gutter="20" style="display: flex;">
          <el-col :span="12" style="flex: 1;"
            ><div class="searchInput">
              <el-form @submit.native.prevent>
                <el-form-item :label="placeholder">
                  <el-input
                    v-model="searchUserString"
                    :placeholder="placeholder"
                    clearable
                    @keyup.enter.native="getList()"
                  >
                  </el-input>
                </el-form-item>
              </el-form></div
          ></el-col>

          <el-col
            :span="12"
            style="flex: 1; 
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-content: flex-end;
            flex-wrap: nowrap;
            align-items: center;"
          >
            <el-button class="confirm" v-on:click="getList()">{{ $t("search") }} </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="margin-top:20px;">
      <el-button class="confirm" @click="showQRCodeDialog" v-permission="'Qr.CreateQr'">
        {{ $t("createQRCode") }}
      </el-button>
    </div>

    <div class="result-background" style="margin-top:20px;" v-loading="loading">
      <div class="result-table">
        <el-table :data="tableData">
          <el-table-column prop="storeName" :label="$t('storeName')">
            <!-- <template slot-scope="scope">
              <div style="text-align: center;">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('effective')"
                  v-if="scope.row.isEffective"
                  placement="top-start"
                >
                  <svg-icon icon-class="success"></svg-icon>>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="$t('uneffective')" v-else placement="top-start">
                  <svg-icon icon-class="error"></svg-icon>
                </el-tooltip>
              </div>
            </template> -->
          </el-table-column>
          <el-table-column prop="qrCodeUrl" :label="$t('QRCode')">
            <template slot-scope="scope">
              <!-- {{ generateQR(scope.row.qrCodeUrl) }} -->
              <div class="previewQRCode" style="width: 40px; height: 40px;">
                <el-image
                  class="pQRCode"
                  style="border:1px solid #dfdfdf;width: 40px; height: 40px;"
                  :src="
                    generateQR(scope.row.qrCodeUrl, {
                      width: 180,
                      height: 180,
                      margin: 2,
                      color: { dark: scope.row.color ? `${scope.row.color}ff` : '#000000ff' }
                    })
                  "
                ></el-image>
                <!-- <img class="pLogo" v-if="scope.row.logo" :src="scope.row.logo" /> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="enable" :label="$t('status')">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enable"
                @change="changeQRStatus($event, scope.row)"
                v-permission="'Qr.EditQr'"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="amount" :label="$t('amount')">
            <template slot-scope="scope">
              <div v-if="scope.row.amount">
                {{ moneyFormat(scope.row.amount) }}
              </div>
              <div v-else>{{ $t("notFixedAmount") }}</div>
            </template>
          </el-table-column>
          <el-table-column width="320" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="showDownloadFn(scope.row)">
                  {{ $t("download") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>

                <el-button
                  size="mini"
                  type="text"
                  @click.native.stop="deleteQRCode(scope.row)"
                  style="color:#999"
                  v-permission="'Qr.DeleteQr'"
                >
                  {{ $t("delete") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          background
          layout=" prev, pager, next, sizes, total"
          :total="total"
          :page-size="currentPageSize"
          :page-sizes="[10, 15, 20]"
          :current-page="currentPage"
          @current-change="currentChange"
          @size-change="sizeChange"
          class="common-pagination"
        >
        </el-pagination>
      </div>
    </div>

    <modal-dialog
      :show-dialog.sync="formDrawer"
      :dialog-title="$t('createQRCode')"
      :dialogWidth="'786px'"
      @closeDialog="closeQRCreateDialog"
      v-loading="loading"
    >
      <el-form ref="qrcodeForm" :model="qrcodeForm" :rules="qrRules" label-position="top">
        <el-row :gutter="20">
          <el-col :span="17"
            ><el-form-item :label="$t('storeName')" prop="storeId">
              <el-select v-model="qrcodeForm.storeId" style="width: 100%;" v-loading="shopListLoading" filterable>
                <el-option
                  v-for="(item, index) in shopList"
                  :key="index"
                  :label="item.storeName"
                  :value="item.storeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('amount')" prop="isFixedAmount">
              <el-radio-group v-model="qrcodeForm.isFixedAmount" style="    white-space: nowrap;">
                <el-radio :label="false">{{ $t("notFixedAmount") }}</el-radio>
                <el-radio :label="true">{{ $t("isFixedAmount") }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="amount" v-if="qrcodeForm.isFixedAmount">
              <el-input
                v-model.number="qrcodeForm.amount"
                :disabled="!qrcodeForm.isFixedAmount"
                placeholder="￥"
                maxlength="10"
              >
                <!-- <template slot="prepend" class="test">￥ </template> -->
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('LOGO')" class="logoUploader">
              <el-row :gutter="0">
                <el-col :span="5">
                  <div class="logoUploadBtn" style="display: block; width:80px;height: 80px;">
                    <el-upload
                      ref="QRLogoUpload"
                      action="uploadLOGO"
                      :show-file-list="false"
                      :auto-upload="false"
                      :on-change="beforeUploadLOGO"
                    >
                      <el-image
                        style="width:76px;height:76px;border:2px solid #f2f2f2;display: block;"
                        v-if="qrcodeForm.logoBase64"
                        :src="qrcodeForm.logoBase64"
                        class="avatar"
                        fit="covor"
                      ></el-image>

                      <i
                        v-else
                        class="el-icon-plus avatar-uploader-icon"
                        style="line-height:76px;width: 76px;height:76px;border:2px solid #f2f2f2;display: block;"
                      ></i>
                    </el-upload>
                    <div v-if="qrcodeForm.logoBase64" style="width: 76px;text-align: center;">
                      <i
                        class="el-icon-close"
                        style="font-size: 20px;color: red;display: block;cursor: pointer;"
                        @click="clearLogoFiles()"
                      ></i>
                    </div></div
                ></el-col>

                <el-col :span="19">
                  <p
                    style="font-size: 16px;line-height:24px;color: #111;margin:15px 0;margin-left:20px;white-space: pre-line;"
                  >
                    {{ $t("logoTips") }}
                  </p>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item :label="$t('color')">
              <el-color-picker v-model="qrcodeForm.color"></el-color-picker>
              <p style="color: #111;line-height: 22px;font-size: 14px;white-space: pre-line;">
                {{ $t("logoColorTips") }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="text-align: right;">
            <div class="previewQRCode">
              <el-image
                class="pQRCode"
                style="border:1px solid #dfdfdf"
                :src="
                  generateQR('testQRCode', {
                    width: 180,
                    margin: 2,
                    color: { dark: qrcodeForm.color ? `${qrcodeForm.color}ff` : '#000000ff' }
                  })
                "
              >
              </el-image>
              <img class="pLogo" v-if="qrcodeForm.logoBase64" :src="qrcodeForm.logoBase64" />
            </div>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:footer>
        <el-button class="cancel" @click="formDrawer = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button class="confirm dialog-btn" type="primary" @click="createQRCode">
          {{ $t("confirm") }}
        </el-button>
      </template>
    </modal-dialog>

    <modal-dialog
      class="editpassword"
      :show-dialog.sync="showDownload"
      :dialog-title="$t('download')"
      :dialogWidth="'786px'"
      @closeDialog="closeDownloadFn"
    >
      <el-form ref="downloadForm" :model="downloadData" label-width="80px">
        <div class="previewQRCode">
          <el-image
            class="pQRCode"
            style="border:1px solid #dfdfdf"
            :src="
              generateQR(downloadData.qrCodeUrl, {
                width: downloadSize,
                height: downloadSize,
                margin: 2,
                color: { dark: downloadData.color ? `${downloadData.color}ff` : '#000000ff' }
              })
            "
          ></el-image>
          <img class="pLogo" v-if="downloadData.logo" :src="downloadData.logo" />
        </div>
        <div class="" style="text-align: center;margin-top:20px;">
          {{ $t("size") }}
          <el-radio-group v-model="downloadSize" style="margin-left:20px">
            <el-radio :label="227">8cm*8cm</el-radio>
            <el-radio :label="454">16cm*16cm</el-radio>
            <el-radio :label="680">24cm*24cm</el-radio>
          </el-radio-group>
        </div>
      </el-form>
      <template v-slot:footer>
        <div style="text-align: center;">
          <el-button class="confirm dialog-btn" type="primary" @click="downloadQRCode">
            {{ $t("downloadQRCode") }}
          </el-button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import QRCodeLib from "qrcode";
import toSJIS from "qrcode/helper/to-sjis";
import { getUserList, userDelete, shoplist, addUser, modifyUser, resetPassword } from "api/user";
import { getQRList, deleteQR, getStoreList, createQR, updateQRStatus } from "api/qrcode";
import { userRoleList } from "api/permission";
import { getSessionStorage } from "@/utils/storage";
import { isArray, cloneDeep } from "lodash";
import pagination from "@/mixins/pagination";
import { moneyFormat, validatePassWord, checkDifferentPassword, validatePhone } from "utils";
import { Message } from "element-ui";

export default {
  mixins: [pagination],
  data() {
    var checkPositiveInteger = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("amountInputErr")));
      }
      if (!Number.isInteger(value)) {
        callback(new Error(this.$t("amountInputErr")));
      } else {
        if (value < 1) {
          callback(new Error(this.$t("amountInputErr")));
        } else {
          callback();
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.userform.userPasswordRepeat !== "") {
          this.$refs.userform.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.userform.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validateEditPass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.editPassword.userPasswordRepeat !== "") {
          this.$refs.editPassword.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validateEditPass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.editPassword.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validatePhoneNum = (rule, value, callback) => {
      if (!validatePhone(value)) {
        callback(new Error(this.$t("userContactInputErr")));
      } else {
        callback();
      }
    };
    return {
      placeholder: this.$t("storeName"),
      formRules: {
        userName: { required: true, message: this.$t("userNameInputErr"), trigger: "blur" },
        userFullName: { required: true, message: this.$t("userFullNameInputErr"), trigger: "blur" },
        userShopList: { required: true, message: this.$t("manageStoreInputErr"), trigger: "change" },
        roleId: { required: true, message: this.$t("userRightInputErr"), trigger: "change" },
        userPhoneNumber: [
          { required: true, message: this.$t("userContactInputErr"), trigger: "blur" },
          { validator: validatePhoneNum, trigger: ["blur", "change"] }
        ],
        userEmail: [
          { required: true, message: this.$t("userEmailInputErr"), trigger: "blur" },
          { type: "email", message: this.$t("userEmailInputErr"), trigger: ["blur", "change"] }
        ],
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ],
        isEffective: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" },
        forceModifyPassword: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" }
      },

      editPasswordRules: {
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validateEditPass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validateEditPass2, trigger: "blur" }
        ]
      },
      qrRules: {
        storeId: { required: true, message: this.$t("storeIdInputErr"), trigger: "blur" },
        isFixedAmount: { required: true, message: this.$t("amountInputErr"), trigger: "blur" },
        amount: [
          { required: true, message: this.$t("amountInputErr"), trigger: "blur" },
          { validator: checkPositiveInteger, trigger: "blur" }
        ]
      },
      showPasswordExpirationDays: true,
      tableData: [],
      loading: false,
      formDrawer: false,
      deleteLoading: false,
      detailLoading: false,
      changePasswordLoading: false,
      userform: {
        roleId: "",
        userId: "",
        isEffective: true,
        userEmail: "",
        userName: "",
        userFullName: "",
        userPhoneNumber: "",
        userShopList: [],
        forceModifyPassword: true,
        allShop: false
      },
      showDownload: false,
      downloadSize: 227,
      downloadData: {
        amount: "",
        color: "",
        enable: true,
        id: 0,
        isFixedAmount: false,
        logo: "",
        qrCodeUrl: "",
        storeId: "",
        storeName: ""
      },
      shopList: [],
      shopListLoading: false,
      qrcodeForm: {
        storeId: "",
        isFixedAmount: false,
        amount: "",
        color: "",
        logoBase64: "",
        logoFile: ""
      },
      formType: "",
      submitLoading: false,

      detailDrawer: false,
      detailInfo: {},
      removeDialog: false,
      userShopList: [],
      userShopListLoading: true,
      shopListMuti: true,

      roleList: [],
      roleListLoading: true,

      passwordDrawer: false,
      userId: null,
      userInfo: {},

      editPassword: {
        roleId: "",
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userShopList: [],
        userPassword: "",
        userPasswordRepeat: ""
      },
      searchUserString: "",
      isCurrentUser: false
    };
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getList();
    //this.getRoleList();
  },

  methods: {
    closeQRCreateDialog() {
      modifyLoading = false;
    },
    downloadQRCode(row) {
      // 获取 canvas 元素和上下文
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // 根据 canvas 大小设置 canvas 和 logo 大小
      const canvasSize = this.downloadSize; // 替换为你想要的 canvas 大小
      const logoSize = calculateLogoSize(canvasSize);

      canvas.width = canvasSize;
      canvas.height = canvasSize;

      let pArr = new Array();

      // 创建底层 QR 码
      const qrCodeURL = this.generateQR(this.downloadData.qrCodeUrl, {
        width: this.downloadSize,
        height: this.downloadSize,
        margin: 2,
        color: { dark: this.downloadData.color ? `${this.downloadData.color}ff` : "#000000ff" }
      }); // 替换为实际的 QR 码文本
      const qrCodeImage = new Image();
      qrCodeImage.src = qrCodeURL;
      pArr.push(qrCodeImage);

      // 创建上层 logo
      const logoURL = this.downloadData.logo; // 替换为实际的 logo 图片 URL
      console.log("logo", logoURL);

      const logoImage = new Image();
      if (logoURL) {
        logoImage.src = logoURL;
        pArr.push(logoImage);
      }

      // 当底层 QR 码和上层 logo 图片都加载完成后
      console.log(pArr);
      Promise.all(
        pArr.map(image => {
          return new Promise(resolve => {
            if (image) {
              image.onload = () => resolve();
            } else {
              resolve();
            }
          });
        })
      )
        .then(resArr => {
          console.log("resArr", resArr);
          // 绘制底层 QR 码
          ctx.drawImage(qrCodeImage, 0, 0, canvasSize, canvasSize);

          // 绘制上层 logo（如果存在的话）
          if (logoURL) {
            const logoX = (canvas.width - logoSize) / 2;
            const logoY = (canvas.height - logoSize) / 2;
            ctx.drawImage(logoImage, logoX, logoY, logoSize, logoSize);
          }

          // 应用样式
          canvas.style.backgroundColor = "#fff";
          canvas.style.borderRadius = "2px";
          canvas.style.border = "6px solid #fff";

          // 创建下载按钮
          // const downloadButton = document.createElement("button");
          // downloadButton.textContent = "下载";
          // downloadButton.addEventListener("click", () => {
          //   // 将 canvas 转换为图像并下载

          // });

          // // 将 canvas 和下载按钮添加到 DOM 中
          // document.body.appendChild(canvas);
          // document.body.appendChild(downloadButton);

          const canvasDataURL = canvas.toDataURL("image/png");
          const a = document.createElement("a");
          a.href = canvasDataURL;
          const fileName = `QRCode_${this.downloadData.storeName}_${this.downloadSize}x${this.downloadSize}.png`;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(error => {
          console.error("加载图片时出错：", error);
        });

      // 根据 canvas 大小计算 logo 大小的函数
      function calculateLogoSize(canvasSize) {
        const baseCanvasSize = 180;
        const baseLogoSize = 40;
        return (canvasSize / baseCanvasSize) * baseLogoSize;
      }
    },
    showDownloadFn(row) {
      console.log(row);
      this.downloadData = row;
      this.showDownload = true;
    },
    closeDownloadFn() {},

    deleteQRCode(row) {
      this.$confirm(this.$t("delQRCodeTips"), "", {
        confirmButtonText: this.$t("delete"),
        confirmButtonClass: "delete"
      }).then(_ => {
        this.loading = true;
        deleteQR({ id: row.id, storeId: row.storeId })
          .then(res => {
            this.loading = false;
            this.$message.success(this.$t("success"));
            this.getList();
          })
          .catch(err => {
            Message({
              showClose: true,
              message: err,
              type: "error",
              duration: 5000
            });
            this.loading = false;
            this.getList();
          });
      });
    },
    clearLogoFiles() {
      console.log("clear logofile");
      console.log(this.$refs);
      this.qrcodeForm.logoBase64 = "";
      this.qrcodeForm.logoFile = "";
      this.$refs["QRLogoUpload"].clearFiles();
    },
    beforeUploadLOGO(file) {
      console.log("logofile:", file);

      const isLt1M = file.size / 1024 / 1024 < 1;
      console.log(isLt1M);
      if (!isLt1M) {
        this.$message.error(this.$t("uploadSizeErr"));
        return false;
      }
      const isJPGorPNG = file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      console.log(isJPGorPNG);
      if (!isJPGorPNG) {
        this.$message.error(this.$t("uploadSizeErr"));
        return false;
      }

      // return isLt1M && isJPGorPNG;
      var f = file.raw;
      this.qrcodeForm.logoFile = file.raw;
      const reader = new FileReader();
      const readerBinary = new FileReader();

      reader.onload = e => {
        this.qrcodeForm.logoBase64 = e.target.result;
      };

      readerBinary.onload = e => {
        this.qrcodeForm.logoFile = e.target.result;
      };

      reader.readAsDataURL(f);
      // readerBinary.readAsBinaryString(f);
    },
    moneyFormat,
    generateQR(url, option = {}) {
      let qrcode;
      option.toSJISFunc = toSJIS;
      option.correctLevel = "H";
      try {
        QRCodeLib.toDataURL(url, option, function(err, url) {
          console.log(err, url);
          qrcode = url;
        });
        console.log("qr", qrcode);
        return qrcode;
      } catch (err) {
        console.error(err);
      }
    },
    createQRCode() {
      this.$refs["qrcodeForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          var formData = new FormData();

          formData.append("StoreId", this.qrcodeForm.storeId);
          formData.append("LogoFile", this.qrcodeForm.logoFile);
          formData.append("IsFixedAmount", this.qrcodeForm.isFixedAmount);
          if (this.qrcodeForm.color) {
            formData.append("Color", this.qrcodeForm.color);
          }
          formData.append("Amount", this.qrcodeForm.amount);

          console.log(formData);
          createQR(formData)
            .then(res => {
              if (res.resultStatus === "Success" && res.statusCode === 200) {
                this.loading = false;
                this.formDrawer = false;
                this.$message.success(this.$t("success"));
                this.qrcodeForm.logoBase64 = "";
                this.qrcodeForm.logoFile = "";
                this.$refs["qrcodeForm"].resetFields();

                this.qrcodeForm = {
                  storeId: "",
                  isFixedAmount: false,
                  amount: "",
                  color: "",
                  logoBase64: "",
                  logoFile: ""
                };
                this.getList();
                console.log(res);
              } else {
                this.loading = false;
              }
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    showQRCodeDialog() {
      this.formDrawer = true;
      this.shopListLoading = true;
      getStoreList({})
        .then(res => {
          console.log(res);
          this.shopListLoading = false;
          if (res.result.length == res.totalCount) {
            this.shopList = res.result;
          } else {
            throw new Error("ShopDataError");
          }
        })
        .catch(err => {
          Message({
            showClose: true,
            message: err,
            type: "error",
            duration: 5000
          });
          this.shopListLoading = false;
        });
    },

    changeQRStatus(e, a) {
      console.log("change to :", e, a);
      this.loading = true;
      updateQRStatus({
        id: a.id,
        storeId: a.storeId,
        enable: e
      })
        .then(res => {
          this.loading = false;
          this.getList();
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        });
    },
    cancel() {
      this.removeDialog = false;
    },
    // 单独处理角色名称
    formatRoleName(roleName) {
      let formatRoleName = "";
      switch (roleName) {
        case "SuperAdmin":
          formatRoleName = this.$t("superAdmin");
          break;
        case "Administrator":
          formatRoleName = this.$t("administrator");
          break;
        case "Manager":
          formatRoleName = this.$t("manager");
          break;
        case "User":
          formatRoleName = this.$t("userRole");
          break;
        default:
          formatRoleName = roleName;
          break;
      }
      return formatRoleName;
    },
    //  单独处理店铺名称
    formatShopName(shopName) {
      let formatShopName = "";
      switch (shopName) {
        case "noshop":
          formatShopName = this.$t("noshop");
          break;
        case "allshop":
          formatShopName = this.$t("allshop");
          break;
        default:
          formatShopName = shopName;
      }
      return formatShopName;
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let storeName = this.searchUserString;
      getQRList({
        storeName,
        pageSize,
        pageIndex
      }).then(response => {
        this.loading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.tableData = response.result;
          this.total = response.totalCount;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    async addUser() {
      this.isCurrentUser = false;
      this.formDrawer = true;
      this.shopListMuti = true;
      this.formType = "add";
      this.detailLoading = true;
      this.getRoleList();
      await this.getUserShopList();

      this.userform = {
        roleId: "",
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userShopList: [],
        userPassword: "",
        userPasswordRepeat: "",
        forceModifyPassword: true,
        allShop: false
      };
      this.detailLoading = false;
    },

    async edit(row) {
      if (row.userId === this.userInfo.userId) {
        this.isCurrentUser = true;
      } else {
        this.isCurrentUser = false;
      }
      this.formDrawer = true;
      this.shopListMuti = true;
      this.formType = "edit";
      this.detailLoading = true;
      this.getRoleList();
      await this.getUserShopList();
      this.userform = {
        roleId: "",
        userId: "",
        isEffective: true,
        userEmail: "",
        userName: "",
        userFullName: "",
        userPhoneNumber: "",
        userShopList: [],
        forceModifyPassword: true
      };
      this.userform.roleId = row.roleId;
      this.userform.userId = row.userId;
      this.userform.isEffective = row.isEffective;
      this.userform.userEmail = row.userEmail;
      this.userform.userName = row.userName;
      this.userform.userFullName = row.userFullName;
      this.userform.userPhoneNumber = row.userPhoneNum;
      this.userform.userShopList = row.shopList.map(item => item.shopId);
      this.userform.forceModifyPassword = row.forceModifyPassword;
      this.detailLoading = false;
    },

    changePassword(row) {
      this.passwordDrawer = true;
      this.formType = "changepassword";
      this.detailLoading = true;
      this.editPassword = {
        userId: "",
        userFullName: "",
        userPassword: "",
        userPasswordRepeat: ""
      };
      this.editPassword.userId = row.userId;
      this.editPassword.userFullName = row.userFullName;
      this.detailLoading = false;
    },

    toDetail(row) {
      if (row.userId === this.userInfo.userId) {
        this.isCurrentUser = true;
      } else {
        this.isCurrentUser = false;
      }
      this.detailDrawer = true;
      this.detailInfo = row;
    },

    userDelete() {
      this.deleteLoading = true;
      userDelete({ userId: this.detailInfo.userId }).then(response => {
        this.deleteLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.$message.success(this.$t("success"));
          this.removeDialog = false;
          this.detailDrawer = false;
          this.getList(this.currentPage);
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    editPasswordSubmit() {
      this.$refs["editPassword"].validate(async valid => {
        if (valid) {
          let data = cloneDeep(this.editPassword);
          data.newPassword = this.editPassword.userPassword;
          this.changePasswordLoading = true;
          resetPassword(data).then(response => {
            this.changePasswordLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.$message.success(this.$t("success"));
              this.passwordDrawer = false;
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },
    userFormSubmit() {
      this.$refs["userform"].validate(valid => {
        if (valid) {
          let action = this.formType === "edit" ? modifyUser : addUser;
          let data = cloneDeep(this.userform);
          if (this.userform.userShopList == "select-all") {
            if (this.$settings.newStoreVersion === true) {
              data.userShopList = this.userShopList.map(item => item.shopId);
            } else {
              data.userShopList = [
                ...this.userShopList[0].options.map(item => item.shopId),
                ...this.userShopList[1].options.map(item => item.shopId)
              ];
            }
          }
          this.submitLoading = true;
          action(data).then(response => {
            this.submitLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.formDrawer = false;
              this.getList(this.currentPage);
              this.$message.success(this.$t("success"));
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },

    shopListChange(value) {
      if (value[value.length - 1] === "select-all") {
        this.userform.userShopList = "select-all";
        this.userform.allShop = true;
        this.shopListMuti = false;
      } else {
        this.userform.allShop = false;
        if (isArray(value)) {
          this.userform.userShopList = value;
        } else {
          this.userform.userShopList = [value];
        }
        this.shopListMuti = true;
      }
    },
    getRoleList(userId) {
      this.roleListLoading = true;
      userRoleList({ userId }).then(response => {
        this.roleListLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.roleList = response.result;
        }
      });
    },

    async getUserShopList(userId) {
      this.userShopListLoading = true;
      let response = await shoplist({ userId });
      if (response.resultStatus === "Success" && response.statusCode === 200) {
        if (this.$settings.newStoreVersion === true) {
          this.userShopList = response.result;
        } else {
          this.userShopList = [
            {
              label: this.$t("shopOffline"),
              options: []
            },
            {
              label: this.$t("shopOnline"),
              options: []
            }
          ];

          if (response.result.length > 0) {
            response.result.forEach(item => {
              if (item.isOnline) {
                this.userShopList[1].options.push(item);
              } else {
                this.userShopList[0].options.push(item);
              }
            });
          }
        }
      }
      this.userShopListLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.store-list-item {
  line-height: 30px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-remove-table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;

  table {
    width: 100%;
    table-layout: fixed;
  }

  td {
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    word-wrap: break-word;
  }

  .name {
    vertical-align: middle;
  }
}
</style>
