import http from "../http";

export const getQRList = params => {
  return http({
    method: "get",
    url: `/api/qr/list`,
    params
  });
};
export const getStoreList = params => {
  return http({
    method: "get",
    url: `/api/qr/storelist`,
    params
  });
};
export const createQR = data => {
  return http({
    method: "post",
    url: `/api/qr/create`,
    data
  });
};

export const updateQRStatus = data => {
  return http({
    method: "put",
    url: `/api/qr/updatestatus`,
    data
  });
};

export const deleteQR = data => {
  return http({
    method: "delete",
    url: `/api/qr/delete`,
    data
  });
};
